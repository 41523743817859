import * as React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Grid, Box, Button } from "@mui/material"
import { Link } from "gatsby"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404: Not found</title>
      </Helmet>
      <h1>{t("title.404")}</h1>
      <p>
        {t("description.404")}{" "}
        <span className="emojiCrying" role="img" aria-label="sad">
          😭😿😢
        </span>
      </p>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="glassmorphism404"
      >
        <Box>
          <StaticImage
            src="../images/404.png"
            width={500}
            layout="constrained"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="404: Not Found"
            placeholder="blurred"
          />
        </Box>
        <Box pb={3}>
          <Button
            component={Link}
            to="/"
            startIcon={<ChevronLeftIcon />}
            className="backHomeButton"
          >
            {t("getbackbutton.404")}
          </Button>
        </Box>
      </Grid>
    </Layout>
  )
}

export default NotFoundPage
